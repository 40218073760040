import React from 'react'
import { useRouter } from 'next/router'
import styled from 'styled-components'

import theme from 'styles/theme'
import Button from 'components/Button'
import NewFoundLogo from 'components/NewFoundLogo'
import { AuthCookie, logout } from 'utils/auth'
import { useOktaAuth } from '@okta/okta-react'
import cookie from 'js-cookie'

interface HeaderProps {
  backButton?: React.ReactElement
}

const Wrapper = styled.header`
  border-bottom: solid 1px ${props => props.theme.colors.lightGrey2};
  position: fixed;
  top: 0;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 75px;
  background-color: ${props => props.theme.colors.white};
`

// We will have navigation here eventually
const Placeholder = styled.div`
  width: 30%;
`

const LogoWrapper = styled.div`
  width: 60%;
  display: flex;
  justify-content: center;
  margin: auto;
`

const LogoutWrapper = styled.div`
  width: 35%;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-left: auto;
`

const Header = (props: HeaderProps) => {
  const router = useRouter()
  const { authState, oktaAuth } = useOktaAuth()
  const handleLogOut = async () => {
    Object.values(AuthCookie).map(value => cookie.remove(value))
    if (authState?.isAuthenticated) {
      await oktaAuth.signOut({ postLogoutRedirectUri: window.location.origin + '/login' })
    } else {
      logout(true)
    }
  }
  return (
    <Wrapper>
      <Placeholder>{props.backButton}</Placeholder>
      <LogoWrapper>
        <NewFoundLogo fill={theme.colors.darkGreen} />
      </LogoWrapper>
      <LogoutWrapper>
        {authState?.isAuthenticated && (
          <>
            <Button type="button" onClick={() => router.push('/metrics')}>
              METRICS
            </Button>
            <Button type="button" onClick={handleLogOut}>
              LOG OUT
            </Button>
          </>
        )}
      </LogoutWrapper>
    </Wrapper>
  )
}

export default Header
