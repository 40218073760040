export enum StaffAuthorizations {
  ActionConsults = 'action-consults',
  ModifySubscriptions = 'modify-subscriptions',
  Prescribe = 'prescribe',
  ViewEscalations = 'view-escalations',
}

export type Staff = {
  createdAt: string
  fullName?: string
  dosespotId?: string
  roleName?: string
  email: string
  fax?: string
  firstName: string
  id: string
  lastName: string
  npiNumber?: string
  phone: string
  updatedAt: string
  wheelId?: string
  healthieId?: string
  photonId?: string
  authorizations: StaffAuthorizations[]
}

export type AuthedStaff = {
  token: string
  refresh: string
  user: Staff
}

export type StaffSnippet = {
  title: string
  text: string
  category: string | null
}
