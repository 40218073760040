import React from 'react'
import capitalize from 'lodash/capitalize'
import Head from 'next/head'
import styled from 'styled-components'

import Header from './Header'

export interface LayoutProps {
  children: React.ReactElement
  backButton?: React.ReactElement
  hideNavBar?: boolean
  titleText: string
  hasPadding: boolean
}

const LayoutWrapper = styled.div<LayoutProps>`
  margin: 75px 0 0 0;
  height: calc(100vh - 95px);
  padding: ${props => (props.hasPadding ? '10px 10px 0 10px' : 0)};

  animation: fadeIn ease 1s;
  -webkit-animation: fadeIn ease 1s;
  -moz-animation: fadeIn ease 1s;
  -o-animation: fadeIn ease 1s;
  -ms-animation: fadeIn ease 1s;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`

const Layout = (props: LayoutProps) => {
  return (
    <div>
      <Head>
        <title>Found Clinic - {capitalize(props.titleText)}</title>
      </Head>
      <Header backButton={props.backButton} />
      <LayoutWrapper {...props}>{props.children}</LayoutWrapper>
      <div id="modal-root"></div>
    </div>
  )
}

export default Layout
